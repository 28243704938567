import React, { useEffect, useState } from 'react';
import './FeaturedEvents.css';
import API from '../API';
import NFTRequired from './images/nft-required-button.png';
import Checkmark from './images/green_checkmark.png';
import { useAuth0 } from '@auth0/auth0-react';
import ConnectWalletButton from './ConnectWalletButton';
import Page from '../components/Page/Page';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Mixpanel from './Mixpanel';
import { usePrimaryCurrency } from './PrimaryCurrencyContext';

const FeaturedEvents = ({ wallets, setWallets }) => {
  const [events, setEvents] = useState([]);
  const { setPrimaryCurrencyAmount, setIsBalanceFetched } = usePrimaryCurrency();
  const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithPopup } = useAuth0();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get('code');

  useEffect(() => {
    Mixpanel.track('Page Viewed', {
      'Page Type': 'Featured Events'
    });
  }, []);

  useEffect(() => {
    async function syncNiftyWallet() {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });
        if (code !== null && code !== '') {
          const response = await API.addNiftyWallet(accessToken, { code: code });
          if (response.status !== 200) {
            console.log('Error getting events.');
            navigate('/');
            return;
          }

          const data = await response.json();
          setWallets(data.wallets);
          navigate('/');
        }
      }
    }
    syncNiftyWallet();
  }, [code, isAuthenticated, getAccessTokenSilently, setWallets, navigate]);

  useEffect(() => {
    async function getEvents() {
      var accessToken;
      if (isAuthenticated) {
        accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });
      }
      const response = await API.getActiveEvents(accessToken);
      if (response.status !== 200) {
        console.log('Error getting events.');
        return;
      }

      const data = await response.json();
      setEvents(data.events);
    }
    getEvents();
  }, [getAccessTokenSilently, isAuthenticated, wallets]);

  useEffect(() => {
    const updateBalance = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });

        const response = await API.getUnityCurrencyBalance(accessToken);
        if (response.status !== 200) {
          // alert('Error getting Unity balance');
          setIsBalanceFetched(false);
          return;
        }

        const sessionData = await response.json();
        setPrimaryCurrencyAmount(sessionData.currency_balance);
        setIsBalanceFetched(true);
      } catch (error) {
        // alert('Error updating Unity balance');
      }
    };

    if (isAuthenticated) {
      updateBalance();
    }
  }, [isAuthenticated, getAccessTokenSilently, setPrimaryCurrencyAmount, setIsBalanceFetched]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <Page showHero={false} wallets={wallets} setWallets={setWallets}>
      <div className="fullscreen" data-testid="FeaturedEvents-test">
        {/* <AppHeader wallets={wallets} setWallets={setWallets} /> */}
        <div className="shows-container">
          {events.map((event) => (
            <div
              className="divShow"
              key={event.title}
              style={{ backgroundImage: `url(${event.background_url})` }}
            >
              <div className="title">{event.title}</div>
              <div className="subtitle">{event.subtitle}</div>
              {event.nft_required && !event.nft_detected && (
                <div className="nft-title">
                  NFT Required <img alt="NFT Required Wallet" src={NFTRequired} />
                </div>
              )}
              {event.nft_required && event.nft_detected && (
                <div className="nft-title nft-detected">
                  NFT Detected <img alt="NFT Detected Checkmark" src={Checkmark} />
                </div>
              )}
              {isAuthenticated && event.nft_required && !event.nft_detected && (
                <ConnectWalletButton
                  className="enter-world"
                  wallets={wallets}
                  setWallets={setWallets}
                />
              )}
              {(!isAuthenticated || !(event.nft_required && !event.nft_detected)) && (
                <button
                  className="enter-world ff-button"
                  disabled={isAuthenticated && !event.id}
                  onClick={async () => {
                    if (!isAuthenticated) {
                      loginWithPopup();
                    } else if (event.id) {
                      navigate('/events/' + event.id);
                    } else {
                      // Show not available yet. Button should be disabled.
                    }
                  }}
                >
                  {!isAuthenticated ? 'Log in' : event.id ? 'Enter World' : 'Coming soon'}
                </button>
              )}
            </div>
          ))}
          {/* <div className="secondary-banner">
                    <a className="view-mint ff-button" href="https://mint.futurefest.io" target="_blank" rel="noopener noreferrer">Mint</a>
                    <img src={SecondBanner} alt="Secondary market banner" />
                </div> */}
          <p style={{ textAlign: 'center', color: 'white' }}>
            For all questions please visit our{' '}
            <a href="https://discord.gg/futurefest">
              <b>Discord</b>
            </a>{' '}
            and{' '}
            <a href="https://www.futurefest.io/support">
              <b>FAQ</b>
            </a>{' '}
            page.
            <br />
            Having trouble connecting your wallet? Check in account settings.
          </p>
        </div>
      </div>
    </Page>
  );
};

export default FeaturedEvents;
