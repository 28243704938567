import React, { useState, useEffect } from 'react';
import './Event.css';
import TwitchPlayer from './TwitchPlayer';
import { Tabs, TabPanel } from 'react-tabs';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import API from '../API';
import Page from './Page/Page';
import IVSPlayer from './IVSPlayer';
import ClientSelectionModal from './ClientSelectionModal';
import DesktopClientLoadingModal from './DesktopClientLoadingModal';
import WelcomeToDesktopBeta from './WelcomeToDesktopBeta';
import Mixpanel from './Mixpanel';
import UnityGame from './UnityGame/UnityGame';

const Event = ({ wallets, setWallets, shopEnabled }) => {
  const [showClientSelectionModal, setShowClientSelectionModal] = useState(false);
  const [showDesktopClientLoadingModal, setShowDesktopClientLoadingModal] = useState(false);
  const [showWelcomeToDesktopBeta, setShowWelcomeToDesktopBeta] = useState(false);
  const [showFurioos, setShowFurioos] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [show, setShow] = useState(null);
  const { eventId } = useParams();
  const [deeplink, setDeeplink] = useState('');
  const [isAppleSilicon, setIsAppleSilicon] = useState(false);
  const isWindowsOS = window.navigator.platform.toLowerCase().includes('win');
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    Mixpanel.track('Page Viewed', {
      'Event ID': eventId,
      'Page Type': 'Event'
    });
  }, [eventId]);

  useEffect(() => {
    async function getShow() {
      var accessToken;
      if (isAuthenticated) {
        accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });
        setAccessToken(accessToken);
      }
      const response = await API.getActiveEvent(accessToken, eventId);

      if (response.status === 404) {
        window.location.href = '/';
        return;
      }

      if (response.status !== 200) {
        console.log(`Error getting event ${eventId}.`);
        return;
      }

      const data = await response.json();
      setShow(data);
    }

    if (isAuthenticated) {
      getShow();
    }
  }, [getAccessTokenSilently, isAuthenticated, eventId]);

  useEffect(() => {
    async function fetchRegions() {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });
        const response = await API.getAvailableServers(accessToken, eventId);
        if (response.status !== 200) {
          console.log('Error getting available servers.');
          return;
        }

        const data = await response.json();
        data.servers.sort((a, b) => (a.id > b.id ? 1 : -1));
        setRegions(data.servers);

        if (data.servers.length > 0) {
          await serverSelected(data.servers[0].id);
        }
      }
    }
    fetchRegions();
  }, [isAuthenticated, getAccessTokenSilently, eventId]);

  async function serverSelected(serverId) {
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'ffclient:play'
      });
      const response = await API.joinServer(accessToken, {
        server_id: serverId,
        event_id: eventId
      });
      if (response.status !== 204) {
        console.log('Issue with joining server.');
      } else {
        setShowFurioos(true);
      }
    }
  }

  useEffect(() => {
    async function getDeeplink() {
      let isAppleSilicon2 = false;
      const highEntropyValues = await navigator.userAgentData.getHighEntropyValues([
        'architecture'
      ]);
      if (highEntropyValues.platform === 'macOS' && highEntropyValues.architecture === 'arm') {
        isAppleSilicon2 = true;
        setIsAppleSilicon(true);
      }

      var accessToken;
      if (isAuthenticated) {
        accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });
        setAccessToken(accessToken);
      }

      const response = await API.getDeeplink();

      if (response.status !== 200) {
        console.log(`Error getting deeplink info.`);
        return;
      }

      const data = await response.json();
      let installerUrl = isAppleSilicon2
        ? show.apple_silicon_installer_url
        : show.windows_installer_url;
      let dl =
        'futurefest://ff_world/?jwt=' +
        encodeURIComponent(accessToken) +
        '&playerType=player&jwtMethod=commandline&api_url=' +
        encodeURIComponent(process.env.REACT_APP_API_URL) +
        '&fullscreen=false&fullscreen_mode=windowed&gameDownloadUrl=' +
        encodeURIComponent(installerUrl) +
        '&freshDownload=false&launcherVersion=' +
        encodeURIComponent(data.launcherVersion);
      if (process.env.REACT_APP_UNITY_ENV !== 'prod') {
        dl += '&env=' + encodeURIComponent(process.env.REACT_APP_UNITY_ENV);
      }
      setDeeplink(dl);
    }

    if (show && isAuthenticated) {
      getDeeplink();
    }
  }, [getAccessTokenSilently, show, isAuthenticated]);

  function onTabChanged(index, lastIndex, event) {
    setShowFurioos(false);
    setShowWelcomeToDesktopBeta(false);
    setShowClientSelectionModal(false);
    setShowDesktopClientLoadingModal(false);
  }

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated &&
    show && (
      <Page wallets={wallets} setWallets={setWallets}>
        <Tabs
          className="navigation"
          selectedTabClassName="navigation_tab--selected tablink-active"
          selectedTabPanelClassName="navigation_tab-panel--selected"
          onSelect={onTabChanged}
          defaultIndex={0}
        >
          <div className="stream-box">
            <TabPanel className="navigation_tab-panel">
              <div className="enter-world-container">
                <ClientSelectionModal
                  showClientSelectionModal={showClientSelectionModal}
                  setShowClientSelectionModal={setShowClientSelectionModal}
                  accessToken={accessToken}
                  callback={() => setShowDesktopClientLoadingModal(true)}
                  furioosCallback={() => setShowFurioos(true)}
                  deeplink={deeplink}
                  isAppleSilicon={isAppleSilicon}
                  showBrowserOption={show.furioos_id ? true : false}
                />
                <DesktopClientLoadingModal
                  showDesktopClientLoadingModal={showDesktopClientLoadingModal}
                  setShowDesktopClientLoadingModal={setShowDesktopClientLoadingModal}
                  callback={() => setShowWelcomeToDesktopBeta(true)}
                  deeplink={deeplink}
                  isAppleSilicon={isAppleSilicon}
                />
                {showWelcomeToDesktopBeta && (
                  <WelcomeToDesktopBeta
                    accessToken={accessToken}
                    deeplink={deeplink}
                    isAppleSilicon={isAppleSilicon}
                  />
                )}
                {showFurioos && <UnityGame accessToken={accessToken} />}
              </div>
            </TabPanel>
            <TabPanel className="navigation_tab-panel">
              {show.stream_url && <IVSPlayer stream_url={show.stream_url} />}
              {!show.stream_url && show.twitch_channel_name && (
                <TwitchPlayer channel={show.twitch_channel_name} height="100%" width="100%" />
              )}
            </TabPanel>
          </div>
        </Tabs>
      </Page>
    )
  );
};

export default Event;
