/*eslint sort-keys: "error"*/
/*eslint-env es6*/
function http(endpointPath, method) {
  return (accessToken, obj) => {
    let path = endpointPath;
    if (typeof path === 'function') {
      path = endpointPath(obj);
    }

    var options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: method
    };
    if (accessToken !== undefined) {
      options.withCredentials = true;
      options.credentials = 'include';
      options.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    if ((method === 'POST' || method === 'PUT') && obj && obj !== 'null' && obj !== 'undefined') {
      options.body = JSON.stringify(obj);
    }
    return fetch(`${process.env.REACT_APP_API_URL}${path}`, options);
  };
}

function get(endpointPath) {
  return http(endpointPath, 'GET');
}

function post(endpointPath) {
  return http(endpointPath, 'POST');
}

function del(endpointPath) {
  return http(endpointPath, 'DELETE');
}
function put(endpointPath) {
  return http(endpointPath, 'PUT');
}

const API = {
  addCardanoWalletAnvil: post('/anvil/wallets/cardano'),
  addEthWallet: post('/multi/user/settings/wallets/eth'),
  addNiftyWallet: post('/multi/user/settings/wallets/nifty'),
  addWallet: post('/multi/user/settings/wallets'),
  adminAddNewCollection: put('/admin/shop/collections'),
  adminAddNewProduct: put(
    (pathParams) => `/admin/shop/collections/${pathParams.collectionId}/products`
  ),
  adminCreateDesktopClient: put('/admin/desktop-clients'),
  adminCreateStripePrice: post('/admin/shop/stripe/price'),
  adminCreateStripeProduct: post('/admin/shop/stripe/product'),
  adminDeleteCollection: del((pathParams) => `/admin/shop/collections/${pathParams.collectionId}`),
  adminDeleteDesktopClient: del((id) => `/admin/desktop-clients/${id}`),
  adminDeleteProduct: del(
    (pathParams) =>
      `/admin/shop/collections/${pathParams.collectionId}/products/${pathParams.productId}`
  ),
  adminGetAvailableDesktopClients: get('/admin/desktop-clients'),
  adminGetDesktopClientPresignedUrl: get(
    (randomObjectKey) => `/admin/desktop-client-presigned-url/${randomObjectKey}`
  ),
  adminGetEventBackgroundPresignedUrl: get(
    (eventId) => `/admin/events/${eventId}/background-presigned-url`
  ),
  adminGetEvents: get('/admin/events'),
  adminGetProductImagePresignedUrl: get(
    (pathParams) =>
      `/admin/shop/collections/${pathParams.collectionId}/products/${pathParams.productId}/image-presigned-url`
  ),
  adminGetServerInfo: get('/admin/server-info'),
  adminGetShopHeroImagePresignedUrl: get('/admin/shop/hero-image-presigned-url'),
  adminGetStorefrontInfo: get('/admin/shop'),
  adminGetStripePrices: get((pathParams) => `/admin/shop/stripe/prices/${pathParams.productId}`),
  adminUpdateCollection: post((pathParams) => `/admin/shop/collections/${pathParams.collectionId}`),
  adminUpdateDeeplink: post('/admin/deeplink-info'),
  adminUpdateEvent: post('/admin/events'),
  adminUpdateProduct: post(
    (pathParams) =>
      `/admin/shop/collections/${pathParams.collectionId}/products/${pathParams.productId}`
  ),
  adminUpdateServerInfo: post('/admin/server-info'),
  adminUpdateStorefrontInfo: post('/admin/shop'),
  createCardanoTransaction: post('/anvil-create-currency-transaction'),
  customizeActionBar: post('/customize-action-bar'),
  customizeActiveAvatar: post('/customize-active-avatar'),
  customizeActiveOutfit: post('/customize-active-outfit'),
  customizeOutfit: post('/customize-outfit'),
  deleteActionFromActionBar: del(
    (pathParams) => `/action_bars/${pathParams.actionBarId}/slots/${pathParams.slotId}`
  ),
  deleteAssetFromOutfit: del(
    (pathParams) =>
      `/avatars/${pathParams.avatarId}/outfits/${pathParams.outfitId}/categories/${pathParams.categoryId}`
  ),
  deleteWallet: del((address) => `/multi/user/settings/wallets/${address}`),
  getActiveEvent: get((eventId) => `/active-events/${eventId}`),
  getActiveEvents: get('/active-events'),
  getAnvilAuthPayload: get('/anvil-auth-payload'),
  getAvailableServers: get((eventId) => `/events/${eventId}/available-servers`),
  getCollection: get((collectionId) => `/collections/${collectionId}`),
  getCurrencyBundles: get('/currency-bundles'),
  getDeeplink: get('/deeplink-info'),
  getDigitalItems: get('/digital-items'),
  getDigitalShops: get('/digital-shops'),
  getFuturebotAssets: get('/futurebot-assets'),
  getProduct: get(
    ({ collectionId, productId }) => `/collections/${collectionId}/products/${productId}`
  ),
  getPurchasedItems: get('/purchase-history'),
  getShopItems: get('/shop-items'),
  getSidepanel: get((eventId) => `/sidepanel${eventId ? `?eventId=${eventId}` : ''}`),
  getUnityCurrencyBalance: get('/unity-currency-balance'),
  getUserNFTs: get('/user-nfts'),
  getWallets: get('/multi/user/settings/wallets'),
  joinServer: post('/join-server'),
  leaveServer: post('/leave-server'),
  postNFTBind: post((nftId) => `/nft/${nftId}/bind`),
  postNFTUnbind: post((nftId) => `/nft/${nftId}/unbind`),
  postPasswordEntry: post('/password-entry'),
  postPurchaseItem: post('/purchase-item'),
  postPurchaseItems: post('/purchase-items'),
  postVirtualCurrencyTransaction: post('/post-virtual-currency-transaction'),
  signedPurchasedTransaction: post('/signed-purchased-transaction'),
  stripeCheckoutSession: post('/stripe-checkout-session'),
  stripeCurrencyCheckout: post('/stripe-currency-checkout'),
  submitVirtualCurrencyTransaction: post('/submitted-virtual-currency-transaction'),
  updateUsername: post('/update-username')
};

export default API;
