import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import './UnityGame.css';
import { FaExpand } from 'react-icons/fa';
import Lottie from 'lottie-react';
import loadingAnimation from '../images/lottie/unity-loading.json';

declare global {
  interface Window {
    createUnityInstance: any;
    unityInstance: any;
  }
}

interface UnityGameProps {
  accessToken?: string;
}

const loadScript = (src: string, type: 'module' | 'text/javascript' = 'text/javascript') => {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.type = type;
    script.onload = () => resolve();
    script.onerror = () => reject(`Failed to load script: ${src}`);
    document.body.appendChild(script);
  });
};

const UnityGame: React.FC<UnityGameProps> = ({ accessToken }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const unityInstanceRef = useRef<any>(null);

  useEffect(() => {
    const loadUnityScript = () => {
      const script = document.createElement('script');
      script.src = 'https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/Build/FutureFestXR.loader.js';
      script.onload = async () => {
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/agora-extension-virtual-background.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/virtualbackground.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/databuilder.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/clientmanager.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/wglwrapper.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/audioeffects.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/eventmanager.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/engineglobals.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/watermark.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/customvideo.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/agorachannel.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/multichannel.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/audiosystem.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/testing.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/agorautils.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/audiomixing.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/agorartcenginev2.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraRTC_N-4.21.0.js');
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/AgoraWebSDK/libs/spatial-audio-main.js', "module");
        await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/TemplateData/hls.min.js');
        await loadScript('https://web-broadcast.live-video.net/1.15.0/amazon-ivs-web-broadcast.js');
	      await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/TemplateData/ivs.js');
	      await loadScript('https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/config.js');

        window.createUnityInstance(
          canvasRef.current,
          {
            arguments: [
              '-playerType',
              'player',
              '-api_url',
              process.env.REACT_APP_API_URL,
              '-env',
              'test',
              '-jwtMethod',
              'commandline',
              '-jwt',
              accessToken
            ],
            dataUrl:
              'https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/Build/FutureFestXR.data',
            frameworkUrl:
              'https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/Build/FutureFestXR.framework.js',
            codeUrl:
              'https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/Build/FutureFestXR.wasm',
            streamingAssetsUrl:
              'https://d2xen1wf0yg342.cloudfront.net/UnityGame/22222/UPC/0.0.1/StreamingAssets',
            companyName: 'FutureFest',
            productName: 'FutureFestXR',
            productVersion: '2.0.0'
          },
          (progress: number) => {
            setLoadingProgress(progress * 100);
          }
        )
        .then((unityInstance: any) => {
          unityInstanceRef.current = unityInstance;
          window.unityInstance = unityInstance;
          setIsLoading(false);
          (document.querySelector('#unity-fullscreen-button') as HTMLElement).onclick = () => {
            unityInstance.SetFullscreen(1);
          };
        })
        .catch((message: any) => {
          alert(message);
        });
      };
      document.body.appendChild(script);
    };

    loadUnityScript();

    // Cleanup function to destroy the Unity instance when component unmounts
    return () => {
      if (unityInstanceRef.current) {
        unityInstanceRef.current.Quit();
        unityInstanceRef.current = null;
        window.unityInstance = null;
      }
    };
  }, [accessToken]);

  return (
    <div id="unity-container" className="unity-desktop flex flex-col items-center justify-center relative h-[80%] mx-auto">
      <Helmet>
        {/* Helmet content remains unchanged */}
      </Helmet>
      <div className="relative w-full h-full">
        <canvas ref={canvasRef} id="unity-canvas" className="bg-[#231f20] w-full h-full"></canvas>
        <button 
          id="unity-fullscreen-button" 
          className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-gray-800 bg-opacity-50 text-white rounded-full hover:bg-opacity-75 transition-all duration-200"
        >
          <FaExpand size={20} />
        </button>
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <Lottie animationData={loadingAnimation} loop={true} style={{ width: '50%', height: '50%' }} />
          </div>
        )}
        {isLoading && (
          <div id="unity-loading-bar" className="absolute bottom-16 left-4 right-4 h-2 bg-gray-200 rounded-full overflow-hidden">
            <div 
              id="unity-progress-bar-full" 
              className="h-full bg-blue-500 transition-all duration-300 ease-out"
              style={{ width: `${loadingProgress}%` }}
            ></div>
          </div>
        )}
      </div>
      <div id="unity-mobile-warning" className="text-red-500 mt-4 md:hidden">
        WebGL builds are not supported on mobile devices.
      </div>
    </div>
  );
};

export default UnityGame;
